body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Caveat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, 'Caveat';
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #ffc93c;
  /* scrollbar handle color */
  /* border-radius: 10px; */
  /* roundness of the scrollbar handle */
  border: 0px solid #f1f1f1;
  /* creates padding around scroll thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
